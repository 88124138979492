import React from 'react';
import { Link } from 'react-router-dom';


const Page2 = () => {
  return (
    <div className="container">
      <h1>This is Page 2</h1>
      <p>Some content for the second page.</p>

      <div className="col-md-4">
<Link to="/page2">
  <img src="./images/xlights/24LED_WallWash_DMX.png" alt="wallwash" className="img-fluid" />
</Link>
</div>
    </div>



  );
};

export default Page2;

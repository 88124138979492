import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Page2 from './components/Page2';
import Page3 from './components/Page3';

//   initial setup  w/ cloudflare, vscode, and github  from :   https://www.youtube.com/watch?v=QNShfexV9Bk

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/page2" element={<Page2 />} />
        <Route path="/page3" element={<Page3 />} />
      </Routes>
    </Router>


  );
}

export default App;

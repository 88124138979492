import React from 'react';

const Page3 = () => {
  return (
    <div className="container">
      <h1>This is Page 3</h1>
      <p>Some content for the third page.</p>
    </div>
  );
};

export default Page3;

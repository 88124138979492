import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';  // Optional CSS for styling

const Home = () => {
  return (
    <div className="container">
      <h1 className="my-4">Welcome to My Website</h1>
      <div className="row">
        <div className="col-md-4">
          <img src="/images/Screenshot-57.png" alt="Image 1" className="img-fluid" />
        </div>
        <div className="col-md-4">
          <Link to="/page2">
            <img src="./images/Uber_profile_pic.jpg" alt="Link to Page 2" className="img-fluid" />
          </Link>
        </div>
        <div className="col-md-4">
          <Link to="/page2">
            <img src="/images/W9LHF.jpg" alt="Link to Page 2" className="img-fluid" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
